<template>
	<button class="bl-icon-button" type="button" @mousedown="onMouseDown($event, false)" @touchstart="onMouseDown($event, true)" tabindex="-1">drag_indicator</button>
	<BlFormField v-if="field" :name="field" />
</template>

<script>
export default {
	name: 'BlCollectionMove',
	props: {
		field: {
			type: String,
			required: true
		},
		updateOnSwap: {
			type: Boolean,
			default: true
		}
	},
	inject: ['blFormCollectionElement', 'blFormCollectionValue', 'blFormCollectionIndex', 'blStandaloneSwapCallback'],
	data() {
		return {
			dragEl: null,
			dragElContent: null,
			startPosition: null,
			lastSwapPosition: null,
			currentIndex: null,
			limits: null,
			elements: null
		}
	},
	created() {
		//Required to set position on newly added fields
		if(this.blFormCollectionValue?.root) this.blFormCollectionValue.initializeMove(this.field)
		this.autoScroll = () => {
			this.parentScrollable.el.scrollTop += this.parentScrollable.way
			this.swapElements()
		}
	},
	unmounted() {
		this.onMouseUp()
	},
	methods: {
		/**
		 * Handles mousedown event, clones hovered element
		 * @param  {object} event
		 */
		onMouseDown(event, touch) {
			this.dragEl = document.createElement('TABLE')
			if(this.blStandaloneSwapCallback) this.dragEl.style.boxShadow = 'none'
			else this.dragEl.style.boxShadow = '1px 4px 12px 0 rgb(0 0 0 / 30%)'
			this.dragEl.classList.add('bl-formtable')
			this.dragEl.style.borderRadius = 0
			this.dragEl.style.zIndex = '100000'
			this.dragEl.style.position = 'absolute'
			let tbody = document.createElement('TBODY')
			document.body.classList.add('grabbing')
			this.dragEl.appendChild(tbody)
			let boundingClient = this.blFormCollectionElement().getBoundingClientRect()
			this.dragEl.style.top = (boundingClient.top - 5) + 'px'
			this.dragEl.style.left = (boundingClient.left - 5) + 'px'
			this.dragEl.style.width = this.blFormCollectionElement().offsetWidth + 'px'
			this.dragElContent = this.blFormCollectionElement().cloneNode(true)
			tbody.appendChild(this.dragElContent)
			document.body.appendChild(this.dragEl)
			this.blFormCollectionElement().style.opacity = 0
			this.$nextTick(() => {
				let newChildren = this.dragElContent.children
				let i = 0
				for(let child of this.blFormCollectionElement().children) {
					newChildren[i].style.width = (child.offsetWidth - 3) + 'px'
					i++
				}
			})
			document.addEventListener(touch ? 'touchend' : 'mouseup', this.onMouseUp)
			document.addEventListener(touch ? 'touchmove' : 'mousemove', this.onMouseMove)
			const clientY = event instanceof TouchEvent ? event.touches[0].clientY : event.clientY
			this.startPosition = clientY - 5
			this.lastSwapPosition = clientY
			this.currentIndex = this.blFormCollectionIndex()
			this.parentScrollable = this.blFormCollectionElement().parentNode.parentNode
			while(this.parentScrollable) {
				const style = getComputedStyle(this.parentScrollable)
				if(this.parentScrollable == document.body) break
				else if(['overflow', 'overflow-x', 'overflow-y'].some(prop => ['auto', 'scroll'].includes(style.getPropertyValue(prop)))) break
				this.parentScrollable = this.parentScrollable.parentNode
			}
			this.parentScrollable = {
				el: this.parentScrollable,
				box: this.parentScrollable.getBoundingClientRect()
			}
			let containerBox = this.blFormCollectionElement().parentNode.parentNode.getBoundingClientRect()
			this.limits = [containerBox.y, containerBox.y + containerBox.height]
			this.elements = Array.from(this.blFormCollectionElement().parentNode.children).map(c => {
				let containerBox = c.getBoundingClientRect()
				return [containerBox.y + this.parentScrollable.el.scrollTop, containerBox.y + containerBox.height + this.parentScrollable.el.scrollTop]
			})
		},
		/**
		 * Handles mouse move and setting to new position
		 * @param  {object} event
		 */
		onMouseMove(event) {
			this.clientY = event instanceof TouchEvent ? event.touches[0].clientY : event.clientY

			//Autoscroll
			if(this.clientY > this.parentScrollable.box.bottom) {
				if(Math.abs(this.parentScrollable.el.scrollHeight - this.parentScrollable.el.scrollTop - this.parentScrollable.el.clientHeight) < 1) this.clearAutoscroll()
				else {
					this.parentScrollable.way = 3
					if(!this.autoScrollInterval) this.autoScrollInterval = setInterval(this.autoScroll, 5)
				}
			}
			else if(this.clientY < this.parentScrollable.box.top) {
				if(this.parentScrollable.el.scrollTop <= 0) this.clearAutoscroll()
				else {
					this.parentScrollable.way = -3
					if(!this.autoScrollInterval) this.autoScrollInterval = setInterval(this.autoScroll, 5)
				}
			}
			else this.clearAutoscroll()

			if(this.clientY < this.limits[0] || this.clientY > this.limits[1]) return
			this.dragEl.style.marginTop = (this.clientY - this.startPosition) + 'px'
			this.swapElements()
		},
		clearAutoscroll() {
			if(this.autoScrollInterval) {
				clearTimeout(this.autoScrollInterval)
				this.autoScrollInterval = null
			}
		},
		swapElements() {
			const clientY = this.clientY + this.parentScrollable.el.scrollTop
			for(let index in this.elements) {
				if(clientY > this.elements[index][0] && clientY < this.elements[index][1] && this.blFormCollectionValue.value[index]) {
					if(index != this.currentIndex) {
						this.swapIndexes(this.currentIndex, index)
						this.currentIndex = index
						if(this.updateOnSwap) this.blFormCollectionValue.dataChange()
					}
					break
				}
			}
		},
		/**
		 * Clears all events listeners and remove ghost elements
		 */
		onMouseUp() {
			this.clearAutoscroll()
			if(this.blFormCollectionElement()) this.blFormCollectionElement().style.opacity = 1
			document.removeEventListener('mouseup', this.onMouseUp)
			document.removeEventListener('touchend', this.onMouseUp)
			document.removeEventListener('mousemove', this.onMouseMove)
			document.removeEventListener('touchmove', this.onMouseMove)
			if(this.dragEl) {
				document.body.removeChild(this.dragEl)
				this.dragEl = null
				this.dragElContent = null
			}
			document.body.classList.remove('grabbing')
			if(!this.updateOnSwap) this.blFormCollectionValue.dataChange()
		},
		/**
		 * Swap indexes in form object to display change
		 * @param  {number} i1
		 * @param  {number} i2
		 */
		swapIndexes(i1, i2) {
			if(this.blStandaloneSwapCallback) this.blStandaloneSwapCallback(i1, i2)
			else {
				let val = this.blFormCollectionValue.value
				let tmp = val[i1]
				val[i1] = val[i2]
				val[i2] = tmp
				this.setPosition()
			}
		},
		/**
		 * Set position field on form
		 */
		setPosition() {
			let pos = 1
			for(let item of this.blFormCollectionValue.value) {
				item.getChild(this.field).setValue(pos, false)
				pos++
			}
		}
	}
}
</script>

<style scoped lang="scss">
button {
	padding: 4px;
	color: var(--bl-legend);
	cursor: grab;
	min-width: 32px;
	min-height: 36px;
}

button:active {
	cursor: grabbing;
}
</style>